import color from "color"

export const darkenColor = (colorVar, adjustmentAmount) => {
  return color(colorVar).darken(adjustmentAmount).hsl().string()
}

export const lightenColor = (colorVar, adjustmentAmount) => {
  return color(colorVar).lighten(adjustmentAmount).hsl().string()
}

export const hsla = (hslColor, alpha) => {
  return color(hslColor)
    .hsl()
    .fade(1 - alpha)
    .string()
}
