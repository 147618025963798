// Resolves a CSS custom property value - var(--our-custom-property) - to its
// literal value at the given point in the DOM tree. This version does NOT work
// with the bare property name (--our-custom-property, not wrapped in a var())
export function resolveCssProperty(propertyValue, elementSelector = "body") {
  const baseElement = document.querySelector(elementSelector)
  const computedStyles = getComputedStyle(baseElement)

  const propertyMatchesCustomPropertyPattern = propertyValue?.match(/var\((.+?)\)/)
  if (!propertyMatchesCustomPropertyPattern) {
    return propertyValue
  }

  const customPropertyName = propertyMatchesCustomPropertyPattern[1]
  return computedStyles.getPropertyValue(customPropertyName)
}

export function resolveCssPropertiesFromObject(propertyObject, elementSelector = "body") {
  const baseElement = document.querySelector(elementSelector)
  const computedStyles = getComputedStyle(baseElement)

  const resolvedProperties = {}

  Object.entries(propertyObject).map(([property, value]) => {
    const valueMatchesCustomPropertyPattern = value?.match(/var\((.+?)\)/)

    if (valueMatchesCustomPropertyPattern) {
      const customPropertyName = valueMatchesCustomPropertyPattern[1]
      resolvedProperties[property] = computedStyles.getPropertyValue(customPropertyName)
    } else {
      resolvedProperties[property] = value
    }
  })

  return resolvedProperties
}
